import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  obtenerCategoriasSubcategorias: (filtros) =>
    RepoUtils.ejecutarPeticionGet(
      filtros,
      "articulosCategorias",
      "obtenerCategoriasSubcategorias"
    ),
  obtenerSubcategorias: (filtros) =>
    RepoUtils.ejecutarPeticionGet(
      filtros,
      "articulosCategorias",
      "obtenerSubcategorias"
    ),
  // POST
  agregarCatSubcat: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "articulosCategorias", "agregar"),
  editarCatSubcat: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "articulosCategorias", "editar"),
  eliminarCatSubcat: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "articulosCategorias", "eliminar"),
};
