
import ArticuloCategoriaService from "../../../Services/ArticuloCategoriaService";
export default {
  name: "articulo-categoria-gestor",
  components: {
    "seccion-categoria": () => import("./components/SeccionCategoria.vue"),
  },
  data() {
    return {
      categorias: [],
      subcategorias: [],
      tituloModalAgregarEditarCatSubcat: "",
      tipoAltaEdicion: "",
      modalAgregarCatSubcat: false,
      editarCatSubcatObj: {},
      categoriaSeleccionadaObj: {},
      modalEditarCatSubcat: false,
      tipoEliminacion: "",
      etiquetaEliminacion: "",
      modalEliminarCatSubcat: false,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerCategorias(false);
      this.loader(false);
    },
    async obtenerCategorias(loader = true) {
      if (loader) this.loader(true);
      let filtros = { status: [200], ordenar: "nombre_asc" };
      await ArticuloCategoriaService.obtenerCategoriasSubcategorias(filtros)
        .then((resp) => {
          this.categorias = resp;
          if (!this._.isEmpty(this.categorias[0])) {
            this.categoriaSeleccionadaObj = this.categorias[0];
            this.subcategorias = this.categorias[0].subcategorias;
          }
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
        })
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerSubcategorias(loader = true) {
      if (loader) this.loader(true);
      let filtros = {
        categoriaPadreId: this.categoriaSeleccionadaObj.categoria_id,
        status: [200],
        ordenar: "nombre_asc",
      };
      await ArticuloCategoriaService.obtenerSubcategorias(filtros)
        .then((resp) => {
          this.subcategorias = resp;
          this.categorias.map((cat) => {
            if (cat.categoria_id == this.categoriaSeleccionadaObj.categoria_id)
              return (cat.subcategorias = resp);
          });
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
        })
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async agregarCategoria(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        nombre: this.editarCatSubcatObj.nombre,
        esPrincipal: "si",
      };
      await ArticuloCategoriaService.agregarCatSubcat(datos)
        .then(async (resp) => {
          this.cerrarModalAgregarCatSubcat();
          await this.obtenerCategorias(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async agregarSubcategoria(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        nombre: this.editarCatSubcatObj.nombreSubcategoria,
        esPrincipal: "no",
        categoriaId: this.categoriaSeleccionadaObj.categoria_id,
      };
      await ArticuloCategoriaService.agregarCatSubcat(datos)
        .then(async (resp) => {
          this.cerrarModalAgregarCatSubcat();
          await this.obtenerSubcategorias(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarCatSubcat(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        categoriaId: this.editarCatSubcatObj.categoria_id,
        nombre: this.editarCatSubcatObj.nombre,
      };
      await ArticuloCategoriaService.editarCatSubcat(datos)
        .then(async (resp) => {
          if (this.tipoAltaEdicion == "categoria")
            await this.obtenerCategorias(false);
          else await this.obtenerSubcategorias();
          this.cerrarModalEditarCatSubcat();
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async eliminarCatSubcat(loader = true) {
      let esPrincipal =
        this.editarCatSubcatObj.categoria_padre_id == null ? "si" : "no";

      // Se validan subcategorias
      if (esPrincipal == "si") {
        if (!this._.isEmpty(this.editarCatSubcatObj.subcategorias))
          return this.mostrarAlerta(
            "No se puede eliminar la categoría debido a que tiene subcategorías activas",
            "error"
          );
      }
      if (loader) this.loader(true);
      let datos = {
        categoriaId: this.editarCatSubcatObj.categoria_id,
        esPrincipal: esPrincipal,
      };
      await ArticuloCategoriaService.eliminarCatSubcat(datos)
        .then(async (resp) => {
          if (this.tipoEliminacion == "categoria")
            await this.obtenerCategorias(false);
          else await this.obtenerSubcategorias();
          this.cerrarModalEliminarCatSubcat();
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    seleccionarCategoria(categoria) {
      this.categoriaSeleccionadaObj = _.cloneDeep(categoria);
      this.subcategorias = _.cloneDeep(categoria.subcategorias);
    },
    manejarAltaCatSubcat() {
      if (this.tipoAltaEdicion == "categoria") this.agregarCategoria();
      else this.agregarSubcategoria();
    },
    abrirModalAgregarCategoria() {
      this.tituloModalAgregarEditarCatSubcat = "Agregar categoría";
      this.tipoAltaEdicion = "categoria";
      this.modalAgregarCatSubcat = true;
    },
    cerrarModalAgregarCatSubcat() {
      this.editarCatSubcatObj = {};
      this.tituloModalAgregarEditarCatSubcat = "";
      this.tipoAltaEdicion = "";
      this.modalAgregarCatSubcat = false;
    },
    abrirModalAgregarSubcategoria() {
      this.tituloModalAgregarEditarCatSubcat = "Agregar subcategoría";
      this.tipoAltaEdicion = "subcategoria";
      this.modalAgregarCatSubcat = true;
    },
    abrirModalEditarCategoria(categoria) {
      this.editarCatSubcatObj = this._.cloneDeep(categoria);
      this.tituloModalAgregarEditarCatSubcat = "Editar categoría";
      this.tipoAltaEdicion = "categoria";
      this.modalEditarCatSubcat = true;
    },
    abrirModalEditarSubcategoria(subcategoria) {
      this.editarCatSubcatObj = this._.cloneDeep(subcategoria);
      this.tituloModalAgregarEditarCatSubcat = "Editar subcategoría";
      this.tipoAltaEdicion = "subcategoria";
      this.modalEditarCatSubcat = true;
    },
    cerrarModalEditarCatSubcat() {
      this.editarCatSubcatObj = {};
      this.tituloModalAgregarEditarCatSubcat = "";
      this.tipoAltaEdicion = "";
      this.modalEditarCatSubcat = false;
    },
    abrirModalEliminarCategoria(categoria) {
      this.tipoEliminacion = "categoria";
      this.etiquetaEliminacion = "categoría";
      this.editarCatSubcatObj = this._.cloneDeep(categoria);
      this.modalEliminarCatSubcat = true;
    },
    abrirModalEliminarSubcategoria(subcategoria) {
      this.tipoEliminacion = "subcategoria";
      this.etiquetaEliminacion = "subcategoría";
      this.editarCatSubcatObj = this._.cloneDeep(subcategoria);
      this.modalEliminarCatSubcat = true;
    },
    cerrarModalEliminarCatSubcat() {
      this.tipoEliminacion = "";
      this.etiquetaEliminacion = "";
      this.editarCatSubcatObj = {};
      this.modalEliminarCatSubcat = false;
    },
  },
};
